$primary: #2eca6a;
$info: #5A67D8;
$danger: #ba1b1b;

//$highlight: #219e98;
//$primaryHoverColor: #219e98;
//$backgroundColorHover: rgba($primary, .2);

$transition: .2s ease-in-out;
//$offcanvas-transition-duration: .2s;

// würden die btn fokus dinger weg machen
//$input-btn-focus-box-shadow: none;
//$input-btn-focus-width: 0;