@import "../../styles/variables";

nav {
  background-color: #fff;
  transition: box-shadow 0.15s ease-in-out;

  &.navbar {
    &.navbar-light {
      .brand-custom {
        font-size: 1.5em;
        font-weight: bolder;
        color: $primary;

        span {
          color: #000;
        }
      }
      .getstarted {
        background: $primary;
        padding: 8px 10px;
        border-radius: 4px;
        color: #fff;

        &:hover {

          color: #fff;
        }
      }
    }
  }

  &.scrolled {
    box-shadow: rgb(0 0 0 / 5%) 0 2px 4px 0;
  }
}