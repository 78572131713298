@import "../../styles/variables";

.sidebar-overlay {
  position: fixed;
  z-index: 1030;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;

  &:hover {
    cursor: default;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  width: 260px;
  padding: 1rem 1rem;
  overflow-y: auto;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
  z-index: 1040;
  position: static;
  top: 0;
  bottom: 0;
  background-color: #323246;

  &.is-active {
    margin-left: 0;
  }

  .sidebar-header {
    text-align: center;
    padding: 0 1.25rem 0.875rem 1.25rem;
    font-size: 1.25rem;


    a {
      color: #fff;
      display: inline-flex;
      font-weight: 600;
      transition: $transition;
    }

    span {
      color: #fff;
      transition: $transition transform;
    }

    &:hover span {
      transform: rotate(-5deg) scale(1.1);
    }

  }

  .nav-list {
    flex: 1 1 0;
  }

  .nav-link {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    padding: 0.75rem;
    color: #fff;
    border-radius: 0.5rem;
    margin-bottom: .5rem;
    transition: .25s;
    cursor: pointer;

    &:hover, &.active {
      background-color: rgba($primary, .9);
    }

    .nav-icon {
      font-size: 20px;
    }
  }
}

.nav-user {
  .nav-user-dropdown-custom .dropdown-menu-end {
    padding: 0;
  }

  .dropdown-toggle:after {
    border: none;
    content: "";
    display: inline-block;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    width: 0;
  }

  .nav-user-info {
    p {
      margin-bottom: 0;
    }
  }
}

.page-wrapper {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  overflow: hidden;

  .app-content {
    flex: 1 1 0;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.toggle-button {
  background-color: transparent;
  background-image: none;
  outline: none;
  border: none;
}

@media (max-width: 1200px) {
  .sidebar {
    position: fixed;
    top: 0;
    margin-left: -260px;
  }

}
