$avatar-sizes: 16, 24, 32, 48, 64, 96, 128 !default;
@use "sass:math";

@mixin avatar($size) {
  max-height: $size + px;
  width: $size + px;
  height: 100%;
  font-size: round(math.div($size, 3) * 2) + px;
  line-height: $size + px;
}

.avatar {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include avatar(32);
}

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @each $size in $avatar-sizes {
      .avatar#{$infix}-#{$size} {
        @include avatar($size);
      }
    }
  }
}

