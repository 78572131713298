* {
  font-family: 'Montserrat', sans-serif;
}

body {
  background: #f8f8f8;
}

p {
  margin-bottom: 0;
}

a {
  text-decoration: none;
  color: $primary;
}

section {
  position: relative;
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
}

.btn-primary,
.btn-primary:focus,
.btn-primary:active {
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.btn-outline-primary,
.btn-outline-primary:focus,
.btn-outline-primary:active {
  &:hover {
    color: #fff;
  }
}

.input-group {
  .input-group-text {
    background-color: #fff;
  }
}

.required:after {
  content: "*";
  position: relative;
  font-size: inherit;
  color: $danger;
  padding-left: 0.25rem;
  font-weight: 700;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .content-wrap {
    flex: 1 1 0;
  }
}

@include media-breakpoint-up(lg) {
  section {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}